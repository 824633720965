import config from "../../includes/Config";
import Service from "./Service";
import Timer from "./Timer";
import bridge from "@vkontakte/vk-bridge";

import container from "../../container";
import LoggerInterface from "../Interfaces/LoggerInterface";
import TokensVKStorage from "./TokensVKStorage";

import {
    TOKEN_NOT_VALID_ERROR,
    USER_AUTH_ERROR,
    USER_DENIED_ERROR,
    INVALID_PARAMS_ERROR,
    WIDGET_ACCESS_ERROR,
} from "../Constants";

import { Widget } from "../Structures";

import {
    WIDGET_PUBLISH_FLUD_CONTROL,
    WIDGET_ACCESS_ERROR_MESSAGE,
    UNSUPORTED_PUBLISH_PLATFORM_ERROR_MESSAGE,
} from "../ErrorMessages";

import {
    PublishWidgetParams,
    UploadImageParams,
    UploadDocumentAsImageParams,
} from "../Structures";

import * as Sentry from "@sentry/react";

const WIDGETS_TOKEN_SCOPE = "app_widget";

class WidgetService extends Service {
    launchParams: any;
    logger: LoggerInterface;

    constructor(launchParams, logger: LoggerInterface) {
        super();

        this.launchParams = launchParams;
        this.logger = logger;
    }

    async create(data: object) {
        try {
            const requestData = this._createRequestData(data);

            const response = await fetch(config.createWidgetUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9029, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при создании виджета",
            };
        }
    }

    async updateBody(widget_id: string, code: object) {
        try {
            const requestData = this._createRequestData({
                code: JSON.stringify(code),
            });

            const response = await fetch(
                `${config.updateWidgetUrl}/${widget_id}`,
                {
                    method: "POST",
                    body: requestData,
                }
            );

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9030, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при изменении настроек виджета",
            };
        }
    }

    async updateName(widget_id: string, name: string) {
        try {
            const requestData = this._createRequestData({ name });

            const response = await fetch(
                `${config.updateNameWidgetUrl}/${widget_id}`,
                {
                    method: "POST",
                    body: requestData,
                }
            );

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9031, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при изменении имени виджета",
            };
        }
    }

    async updateAudience(widget_id: string, audience: object) {
        try {
            const requestData = this._createRequestData({
                audience: JSON.stringify(audience),
            });

            const response = await fetch(
                `${config.updateWidgetAudienceUrl}/${widget_id}`,
                {
                    method: "POST",
                    body: requestData,
                }
            );

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9033, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: 'Возникла ошибка при обновлении аудитории виджета',
            };
        }
    }

    /**
     * Загрузить изображение файлом
     * @param data
     */
    async uploadImage(data: UploadImageParams, retriesCount = 0) {
        let token = await this.getToken();

        if (false == token.success) {
            return {
                result: "error",
                message: "Ошибка при получении токена сообщества",
            };
        }

        data.token = token.token;

        const requestData = this._createRequestData(data);

        const response = await fetch(config.uploadImageUrl, {
            method: "POST",
            body: requestData,
        });

        const result = await this._formattedResponse(response);

        /**
         * Если ответ с ошибкой авторизации по токену
         * Сбросим токен и выполним запрос снова
         */
        if (result.error_code) {
            if (
                [USER_AUTH_ERROR, TOKEN_NOT_VALID_ERROR].indexOf(
                    result.error_code
                ) >= 0 &&
                retriesCount < 3
            ) {
                await TokensVKStorage.dropCommunityToken(WIDGETS_TOKEN_SCOPE);
                return this.uploadImage(data, retriesCount + 1);
            }
        }

        return result;
    }

    /**
     *
     * @param data Загрузить изобаражение по ссылке на документ VK
     */
    async uploadDocumentAsImage(data: UploadDocumentAsImageParams, retriesCount = 0) {
        let token = await this.getToken();

        if (false == token.success) {
            return {
                result: "error",
                message: "Ошибка при получении токена сообщества",
            };
        }

        data.token = token.token;

        const requestData = this._createRequestData(data);

        const response = await fetch(config.uploadDocumentAsImageUrl, {
            method: "POST",
            body: requestData,
        });

        const result = await this._formattedResponse(response);

        if (result.error_code) {
            if (
                [USER_AUTH_ERROR, TOKEN_NOT_VALID_ERROR].indexOf(
                    result.error_code
                ) >= 0 &&
                retriesCount < 3
            ) {
                await TokensVKStorage.dropCommunityToken(WIDGETS_TOKEN_SCOPE);
                return this.uploadDocumentAsImage(data, retriesCount + 1);
            }
        }

        return result;
    }

    /**
     * Get all widget for group by given group id
     * @param group_id number
     */
    async getAll(group_id: number) {
        try {
            let requestData = this._createRequestData({});

            const response = await fetch(
                `${config.getWidgetsUrl}/${group_id}`,
                {
                    method: "POST",
                    body: requestData,
                }
            );

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9038, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при получении виджетов",
            };
        }
    }

    /**
     * Delete widget by given ID
     */
    async deleteWidgets(widgets_ids: String[]) {
        try {
            const requestData = this._createRequestData({ ids: widgets_ids });

            const response = await fetch(`${config.deleteWidgetUrl}`, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9105, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при удалении виджетов"
            };
        }
    }

    async sortWidgets(widgets: object[]) {
        try {
            const requestData = this._createRequestData({
                widgets: JSON.stringify(widgets),
            });

            const response = await fetch(config.sortWidgetsUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9106, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при сортировке виджетов"
            };
        }
    }

    /**
     *
     * @param data
     */
    async publish(data: PublishWidgetParams) {
        if (Timer.check()) {
            return {
                result: "error",
                isTimer: true,
                timeleft: Timer.getTimeLeft(),
                message: WIDGET_PUBLISH_FLUD_CONTROL,
            };
        }

        const requestData = this._createRequestData({
            ids: data.ids,
            group_id: data.group_id,
            code: data.code,
            type: data.type,
            delete_ids: data.delete_ids,
            update_last_p_state_ids: data.update_last_p_state_ids,
        });

        try {
            const previewResult = await bridge.send(
                "VKWebAppShowCommunityWidgetPreviewBox",
                {
                    group_id: data.group_id,
                    type: data.type,
                    code: data.code,
                }
            );

            Timer.set();

            if (previewResult.result === true) {
                try {
                    await fetch(config.publishWidgetsUrl, {
                        method: "POST",
                        body: requestData,
                    });

                    const allWidgets = await this.getAll(data.group_id);

                    return allWidgets;
                } catch (e) {
                    this.logger.error(
                        { code: 9032, message: e.message },
                        "widget_publish_error",
                        "WidgetService.ts"
                    );

                    Sentry.captureException(e);

                    return {
                        result: "error",
                        message: "Возникла ошибка при публикации виджета",
                    };
                }
            } else {
                return {
                    result: "error",
                    message: "Возникла Ошибка при публикации виджета",
                };
            }
        } catch (e) {
            this.logError(e, data.code);

            Sentry.captureException(e);

            Timer.set();

            return this.formattedPublishError(e);
        }
    }

    async discard(widget: Widget) {
        try {
            const data = this._createRequestData({});

            const response = await fetch(`${config.discardWidgeUrl}/${widget.id}`, {
                method: "POST",
                body: data,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9107, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при отмене изменений"
            };
        }
    }

    async clone(widget_id: string) {
        try {
            const requestData = this._createRequestData({
                widget_id: widget_id,
            });

            const response = await fetch(config.cloneWidgetUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9108, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при клонировании виджета"
            };
        }
    }

    async copyToCommunity(
        widget_ids: string[],
        communities_ids: number[],
        vk_group_id: number
    ) {
        try {
            const requestData = this._createRequestData({
                widget_ids,
                communities_ids,
                vk_group_id,
            });

            const response = await fetch(config.copyToCommunityUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9109, message: e.message },
                "request_error",
                "WidgetService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при копировании виджетов"
            };
        }
    }

    /**
     * Получить токен сообщества из хранилища
     */
    async getToken() {
        /**
         * Попробуем получить токен из хранилища ВК
         */
        let token = await TokensVKStorage.getCommunityToken(
            WIDGETS_TOKEN_SCOPE
        );

        if (token) {
            return {
                success: true,
                token: token,
            };
        } else {
            // Если токен отсутствует - запросим
            return await this.requestToken();
        }
    }

    /**
     * Запрос на получение токена от пользователя
     */
    async requestToken() {
        if (false === bridge.supports("VKWebAppGetCommunityToken")) {
            return {
                success: false,
                message: "Не поддерживается получение токена сообщества",
            };
        }

        try {
            const token = await bridge.send(
                "VKWebAppGetCommunityToken",
                {
                    app_id: this.launchParams.params.vk_app_id,
                    group_id: this.launchParams.params.vk_group_id,
                    scope: WIDGETS_TOKEN_SCOPE,
                }
            );

            this.saveToken(token.access_token)

            return {
                success: true,
                token: token.access_token,
            };
        } catch (e) {
            let message = "";
            if (e.error_data) {
                message = e.error_data.error_reason;
            }
            return {
                success: false,
                error: message,
            };
        }
    }

    async saveToken(token: string) {
        await TokensVKStorage.setCommunityToken(WIDGETS_TOKEN_SCOPE, token);
    }

    async hasToken() {
        let token = await TokensVKStorage.getCommunityToken(
            WIDGETS_TOKEN_SCOPE
        );
        return !!token;
    }

    /**
     * Получение серверного времени VK
     */
    async getVkServerTime() {
        const requestData = this._createRequestData({});

        const response = await fetch(config.getVkServerTime, {
            method: "POST",
            body: requestData,
        });

        return this._formattedResponse(response);
    }

    formattedPublishError(e) {
        let errorMsg = ''

        if (e.error_type) {
            switch (e.error_type) {
                case 'client_error':
                case 'auth_error':
                    errorMsg = e.error_data.error_reason
                    break;
                case 'api_error':
                    errorMsg = e.error_data.error_msg
                    break;

                default:
                    break;
            }
        }

        const result = {
            result: "error",
            message: errorMsg
                ? errorMsg
                : "Неизвестная ошибка. Пожалуйста, обратитесь в техническую поддержку.",
        };

        if (e.error_data) {
            if (e.error_data.error_code === USER_DENIED_ERROR) {
                result.message = "Публикация отменена";
                result.result = "cancel";
            } else if (
                e.error_data.error_code === INVALID_PARAMS_ERROR ||
                e.error_data.error_code === 1 ||
                e.error_data.error_code === 5
            ) {
                result.message = e.error_data.error_reason;
            } else if (e.error_data.error_code === WIDGET_ACCESS_ERROR) {
                result.message = `${WIDGET_ACCESS_ERROR_MESSAGE} ${e.error_data.error_reason}`;
            } else if (e.error_data.error_reason === "Unsupported platform") {
                result.message = UNSUPORTED_PUBLISH_PLATFORM_ERROR_MESSAGE;
            }

            // USER_AUTH_ERROR, TOKEN_NOT_VALID_ERROR - нужно ли обрабатывать ошибки доступа?
        }

        return result;
    }

    logError(e, code = "") {
        try {
            let message =
                e.error_data && e.error_data.error_reason
                    ? e.error_data.error_reason
                    : JSON.stringify(e);

            if (code) {
                message = `${message} ${code}`;
            }

            let error_code;

            if (
                e.error_data &&
                e.error_data.error_code &&
                e.error_data.error_code
            ) {
                error_code = e.error_data.error_code;
            }

            // Не логируем ошибку, если пользователь просто отменил публикацию
            if (error_code !== USER_DENIED_ERROR) {
                this.logger.error(
                    {
                        message: message,
                        code:
                            e.error_data && e.error_data.error_code
                                ? e.error_data.error_code
                                : JSON.stringify(e),
                    },
                    "widget_publish_error",
                    "WidgetService.ts"
                );
            }
        } catch (e) {
            console.log(e);
        }
    }
}

const instance = new WidgetService(
    container.get("LaunchParams"),
    container.get("logger")
);

export default instance;
