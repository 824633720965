import React, { Component } from "react";
import bridge from "@vkontakte/vk-bridge";
import "@vkontakte/vkui/dist/vkui.css";
import { View, Panel, PanelHeader, Root, PanelSpinner } from "@vkontakte/vkui";

import container from "./container";

/**
 * Main app routes
 */
import IndexRoute from "./Routes/IndexRoute";
import ShareRoute from "./Routes/ShareRoute";
import LandingRoute from "./Routes/LandingRoute";
import NotSupportedRoute from "./Routes/NotSupportedRoute";
import FatalError from "./Routes/FatalError";
import PagesItemRoute from "./Routes/PagesItemRoute";
/**
 * Services
 */
import GuideService from "./includes/Services/GuideService";
import WidgetsService from "./includes/Services/WidgetService";
import Timer from "./includes/Services/Timer";
import SubscriptionsToEntities from "./includes/Mappings/SubscriptionsToEntities";

import UrlParams from "./includes/UrlParams";
import { MODAL_NULL } from "./includes/Constants";

/**
 * Context management
 */
import { Context } from "./context";
import {
    mainPopout,
    mainSnackbar,
    subscriptions,
    bots,
    groupSiteData,
    initialSettings,
    guide,
    widgets,
    lastActiveTypeFilter,
    currentModal,
    inputDynamic,
    location,
    scheme,
    urlParams,
    resizer,
    getInitialState,
    pages,
    payment,
} from "./includes/AppContextManagement";

import {
    parseHash,
    parsePageIdFromHash,
    parseSubscriptionIdFromHash,
    setHash,
} from "./includes/Helpers/Helpers";
import ErrorBoundary from "./ErrorBoundary";
import * as Sentry from "@sentry/react";

let _UrlParams = new UrlParams();
const lp = container.get("LaunchParams");
const Logger = container.get("logger");
let _Subscriptions = container.get("SubscriptionsService");

class App extends Component<any, any> {
    mainPopout = mainPopout(this);
    mainSnackbar = mainSnackbar(this);
    groupSiteData = groupSiteData(this);
    subscriptions = subscriptions(this);
    bots = bots(this);
    initialSettings = initialSettings(this);
    guide = guide(this);
    widgets = widgets(this);
    lastActiveTypeFilter = lastActiveTypeFilter(this);
    currentModal = currentModal(this);
    inputDynamic = inputDynamic(this);
    location = location(this);
    scheme = scheme(this);
    urlParams = urlParams(this);
    resizer = resizer(this, _UrlParams, container.get("logger"));
    pages = pages(this);
    payment = payment(this);

    group = {};
    user = {};

    // Высота рабочей области iframe при инициализации приложения
    appInitialHeight = (() => {
        try {
            let initialAppHeight = Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            );

            return initialAppHeight;
        } catch (e) {
            console.log(e);
            return 800;
        }
    })();

    constructor(props) {
        super(props);

        bridge.send("VKWebAppInit", {});

        if (bridge.supports("VKWebAppGetClientVersion")) {
            bridge.send("VKWebAppGetClientVersion", {});
        } else {
            console.log("Event VKWebAppGetClientVersion not supported");
        }

        const setLoc = (loc) => {
            if (window.location.hash.indexOf("#s=") >= 0) {
                this.setState({ subscription_id: loc.s_id });
            }

            if (!loc) return;

            if (loc.widget_id) {
                // Проверяем редирект, если при навигации виджет уже не доступен
                if (this.widgets.isExists(loc.widget_id)) {
                    this.widgets.setActiveById(loc.widget_id);
                    this.location.set(loc);
                } else {
                    this.location.homeWidgets();
                }

                return;
            }

            if (loc.page_id) {
                // Проверяем редирект, если при навигации лендинг уже не доступен
                if (this.pages.isExists(loc.page_id)) {
                    this.pages.setActivePageId(loc.page_id);
                    this.location.set(loc);
                } else {
                    this.location.homePages();
                }

                return;
            }

            if (loc.view === "widgets" && loc.panel === "name") {
                this.widgets.setNew(loc.type_api);
            }

            this.location.set(loc);
        };

        // Отдельный обработчик для навигации по хэшу
        window.addEventListener("hashchange", (e) => {
            try {
                const loc = parseHash(window.location.hash);

                // Если руками переходим на подписную страницу
                if (loc.list === "subscriptions" && loc.s_id) {
                    if (_UrlParams.params.vk_platform === "desktop_web") {
                        document.body.style.overflowY = "hidden";
                    }
                } else {
                    document.body.style.overflowY = "auto";
                }

                if (
                    loc.view === "pages-statistic" ||
                    loc.view === "pages-edit"
                ) {
                    this.location.setIsPagesStatisticPreviousPage(true);
                }

                if (loc.list !== "pages") {
                    this.location.setIsPagesStatisticPreviousPage(false);
                }

                setLoc(loc);
            } catch (e) {
                Logger.error(
                    { code: 9002, message: e.message },
                    "set_location_error",
                    "App.js"
                );
                Sentry.captureException(e);
            }
        });

        // Отдельный обработчик для кнопки "Назад"
        window.addEventListener("popstate", (e) => {
            if (this.mainPopout.isActive()) {
                e.preventDefault();
                this.mainPopout.close();
                return false;
            }

            if (this.currentModal.isActive()) {
                e.preventDefault();
                this.currentModal.set(MODAL_NULL);
                return false;
            }
        });

        this.group = {
            vk_group_id: _UrlParams.params.vk_group_id,
        };
        this.user = {
            id: _UrlParams.params.vk_user_id,
        };

        let initialLocationList = "subscriptions";

        // Сразу отобразить страницу "Мои подписки" если есть параметры unsubscribe или d=N
        // Для совместимости со старой версией
        if (
            (_UrlParams.params.unsubscribe &&
                _UrlParams.params.unsubscribe === "unsubscribe") ||
            _UrlParams.params.d
        ) {
            initialLocationList = "mysubscriptions";
        }

        this.state = getInitialState(_UrlParams, initialLocationList);

        if (!_UrlParams.params.vk_group_id) {
            //@ts-ignore
            this.state.route = "landing";
        } else if (_UrlParams.hash.share) {
            //@ts-ignore
            this.state.route = "share";
        } else if (_UrlParams.hash.page) {
            //@ts-ignore
            this.state.route = "page";
        } else if (!bridge.supports("VKWebAppInit")) {
            //@ts-ignore
            this.state.route = "not-supported";
        } else {
            //@ts-ignore
            this.state.route = "index";
        }

        bridge.subscribe(async (e: any) => {
            if (e.detail && e.detail.type) {
                switch (e.detail.type) {
                    case "VKWebAppAccessTokenReceived":
                        if (e.detail.data && e.detail.data.access_token) {
                        }
                        break;
                    case "VKWebAppChangeFragment":
                        const hash = e.detail.data.location;

                        const pageId = parsePageIdFromHash(hash);
                        const subscriptionId =
                            parseSubscriptionIdFromHash(hash);

                        if (pageId) {
                            lp.params.page = pageId;

                            this.setState({
                                route: "page",
                                pageIdForWebView: pageId,
                            });

                            return;
                        }

                        if (subscriptionId) {
                            this.setState({
                                subscription_id: subscriptionId,
                                route: "index",
                            });

                            setHash({
                                list: "subscriptions",
                            });
                        }

                        break;
                    case "VKWebAppAccessTokenFailed":
                        console.log(e);

                        let message = "App access token failed";
                        if (e.detail.data && e.detail.data.error_data) {
                            message = e.detail.data.error_data.error_reason;
                        }

                        // В случае ошибки - покажем пользователю модальное окно с информацией об ошибке
                        this.mainPopout.error(message);

                        Logger.error(
                            {
                                code: 9003,
                                message: JSON.stringify(e),
                            },
                            "AppAccessTokenFailed",
                            "App.js"
                        );

                        Sentry.captureException(e);

                        break;
                    case "VKWebAppCallAPIMethodFailed":
                        if (
                            e.detail.data &&
                            e.detail.data.error_type &&
                            e.detail.data.error_data
                        ) {
                            switch (e.detail.data.error_type) {
                                case 'client_error':
                                case 'auth_error':
                                    if (e.detail.data.error_data.error_reason) {
                                        console.log(e.detail.data.error_data.error_reason);
                                    }

                                    break;
                                case 'api_error':
                                    if (e.detail.data.error_data.error_msg) {
                                        console.log(e.detail.data.error_data.error_msg);
                                    }

                                    break;
                                default:
                                    break;
                            }
                        }
                        break;
                    case "VKWebAppCallAPIMethodResult":
                        break;
                    case "VKWebAppGetClientVersionResult":
                        break;
                    case "VKWebAppInitResult":
                        break;
                    case "VKWebAppResizeWindowResult":
                        break;
                    case "VKWebAppSetLocationResult":
                        break;
                    case "vk-bridge":
                        break;
                    case "VKWebAppViewHide":
                        break;
                    case "VKWebAppUpdateConfig":
                        if (e.detail && e.detail.data && e.detail.data.scheme) {
                            let scheme = e.detail.data.scheme;
                            if (
                                [
                                    "vkcom_dark",
                                    "vkcom_light",
                                    "space_gray",
                                    "bright_light",
                                ].indexOf(scheme) >= 0
                            ) {
                                this.scheme.set(scheme);
                                try {
                                    document.body.setAttribute(
                                        "scheme",
                                        scheme
                                    );
                                } catch (e) {
                                    Logger.error(
                                        { code: 9004, message: e.message },
                                        "init_app_error",
                                        "App.js"
                                    );

                                    Sentry.captureException(e);
                                }
                            }
                        }
                        break;
                    case "VKWebAppViewRestore":
                        Timer.reset();
                        break;
                    default:
                }
            }
        });
    }

    componentDidMount() {
        try {
            const isSingle =
                !!this.location.getSid() || !!this.state.subscription_id;

            if (isSingle) {
                if (_UrlParams.params.vk_platform === "desktop_web") {
                    document.body.style.overflowY = "hidden";
                }
            }

            if (
                ["moder", "editor", "admin"].indexOf(
                    _UrlParams.gets.vk_viewer_group_role
                ) >= 0
            ) {
                // Для администраторов и модераторов сообщества запрашиваем гайд
                this.fetchGuide();
            }

            if (
                ["editor", "admin"].indexOf(
                    _UrlParams.gets.vk_viewer_group_role
                ) >= 0
            ) {
                // Для администраторов и редакторов - запрашиваем необходимые данные
                this.getAppAdminData();
            }
        } catch (e) {
            Logger.error(
                { code: 9005, message: e.message },
                "runtime_error",
                "App.js"
            );

            Sentry.captureException(e);
        }
    }

    async fetchGuide() {
        try {
            const response = await GuideService.get();
            if (response.result === "success") {
                this.guide.set(response.data);
            } else {
                Logger.error(
                    {
                        code: 9006,
                        message: `Fetch guide error - ${JSON.stringify(
                            response
                        )}`,
                    },
                    "runtime_error",
                    "App.js"
                );
                console.log(response);
            }
        } catch (e) {
            Logger.error(
                { code: 9007, message: e.message },
                "runtime_error",
                "App.js"
            );

            Sentry.captureException(e);
        }
    }

    /**
     * Получение данных по сообществу для администраторов
     * - Список групп подписчиков
     * - Список ботов
     * - Данные сообщества
     * - Список администраторов и редакторов сообщества
     */
    async getAppAdminData() {
        let data = {
            vk_group_id: lp.params.vk_group_id,
            vk_user_id: lp.params.vk_user_id,
            params: lp.params,
            offset: 0,
        };

        /**
         * @TODO - переименовать метод в более очевидный
         */
        _Subscriptions.getAdminSubscriptions(data, (result) => {
            if (result.success) {
                this.groupSiteData.setIsConnected(true);

                if (result.items && result.items.length > 0) {
                    this.subscriptions.set(
                        SubscriptionsToEntities(
                            result.items,
                            lp.params.vk_group_id,
                            lp.params.vk_app_id
                        )
                    );
                }

                if (result.bots && result.bots.length) {
                    this.bots.set(result.bots);
                }

                if (result.admins && result.admins.length > 0) {
                    this.groupSiteData.setAdmins(result.admins);
                }
            } else {
                setTimeout(() => {
                    this.getAppAdminData();
                }, 5000);
            }
        });
    }

    changeRoute = (route) => {
        this.setState({ route });
    };

    render() {
        try {
            return (() => {
                switch (this.state.route) {
                    case "index":
                        return (
                            <Context.Provider
                                value={{
                                    snackbar: this.mainSnackbar,
                                    popout: this.mainPopout,
                                    user: this.user,
                                    widgets: this.widgets,
                                    pages: this.pages,
                                    location: this.location,
                                    lastActiveTypeFilter:
                                        this.lastActiveTypeFilter,
                                    currentModal: this.currentModal,
                                    groupSiteData: this.groupSiteData,
                                    subscriptions: this.subscriptions,
                                    bots: this.bots,
                                    initialSettings: this.initialSettings,
                                    guide: this.guide,
                                    resizer: this.resizer,
                                    urlParams: this.urlParams,
                                    inputDynamic: this.inputDynamic,
                                    scheme: this.scheme,
                                    payment: this.payment,
                                }}
                            >
                                <ErrorBoundary>
                                    <IndexRoute
                                        popout={this.state.popout}
                                        UrlParams={_UrlParams}
                                        group={this.group}
                                        user={this.user}
                                        subscription_id={
                                            this.state.subscription_id
                                        }
                                        mainPopout={this.mainPopout}
                                        changeRoute={this.changeRoute}
                                    />
                                    {this.state.snackbar}
                                </ErrorBoundary>
                            </Context.Provider>
                        );
                    case "loading":
                        return (
                            <Root activeView="MainLoading">
                                <View
                                    id="MainLoading"
                                    activePanel="MainLoading_main"
                                >
                                    <Panel id="MainLoading_main">
                                        <PanelHeader></PanelHeader>
                                        <PanelSpinner />
                                    </Panel>
                                </View>
                            </Root>
                        );
                    case "share":
                        return (
                            <Context.Provider
                                value={{
                                    snackbar: this.mainSnackbar,
                                    popout: this.mainPopout,
                                    user: this.user,
                                    currentModal: this.currentModal,
                                    initialSettings: this.initialSettings,
                                    urlParams: this.urlParams,
                                }}
                            >
                                <ShareRoute
                                    popout={this.state.popout}
                                    mainPopout={this.mainPopout}
                                    UrlParams={_UrlParams}
                                />
                            </Context.Provider>
                        );
                    case "not-supported":
                        return (
                            <NotSupportedRoute
                                popout={this.state.popout}
                                mainPopout={this.mainPopout}
                                //@ts-ignore
                                urlParams={_UrlParams.params}
                            />
                        );
                    case "page":
                        return (
                            <Context.Provider
                                value={{
                                    snackbar: this.mainSnackbar,
                                    popout: this.mainPopout,
                                    user: this.user,
                                    currentModal: this.currentModal,
                                    initialSettings: this.initialSettings,
                                    groupSiteData: this.groupSiteData,
                                    urlParams: this.urlParams,
                                    pages: this.pages,
                                    location: this.location,
                                    resizer: this.resizer,
                                    payment: this.payment,
                                }}
                            >
                                <PagesItemRoute
                                    popout={this.state.popout}
                                    mainPopout={this.mainPopout}
                                    urlParams={lp}
                                    group={this.group}
                                    user={this.user}
                                />
                            </Context.Provider>
                        );
                    default:
                        return (
                            <LandingRoute
                                resizer={this.resizer}
                                popout={this.state.popout}
                                mainPopout={this.mainPopout}
                            />
                        );
                }
            })();
        } catch (e) {
            Logger.error(
                { code: 9008, message: e.message },
                "render_error",
                "App.js"
            );

            Sentry.captureException(e);

            return <FatalError />;
        }
    }
}

export default App;
