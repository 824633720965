import React from "react";
import { List, Group, Div, Headline } from "@vkontakte/vkui";
import ItemCardCell from "../Controls/ItemCardCell";
import SubHeader from "../SubHeader";
import { widgets as widgetList } from "../../includes/Constants";
import { WidgetDescription } from "../../includes/Structures";

export interface AddWidgetProps {
    onBack: () => void;
    onWidgetSelect: (widget: WidgetDescription) => void;
    onShowHelp: (widget: WidgetDescription) => void;
}

const AddWidget = (props: AddWidgetProps) => {
    let widgets = widgetList.filter((i) => !i.deprecated);

    const widget = [
        { tiles: widgets.filter((it) => it.type == "tiles"), name: "Плитки" },
        {
            tiles: widgets.filter((it) => it.type == "cover_list"),
            name: "Обложки",
        },
        {
            tiles: widgets.filter(
                (it) => it.type == "list" || it.type == "compact_list"
            ),
            name: "Списки",
        },
        { tiles: widgets.filter((it) => it.type == "table"), name: "Таблицы" },
    ];

    return (
        <>
            <SubHeader onBack={props.onBack} fixed>Добавить виджет</SubHeader>
            {widget.map((item, i) => (
                <Group
                    key={i}
                    header={
                        <Div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Headline level="2" weight="1">
                                {item.name}
                            </Headline>
                        </Div>
                    }
                >
                    <List>
                        {item.tiles.map((it, i) => (
                            <ItemCardCell
                                key={i}
                                img={`img/widgets/${it.img}`}
                                title={it.title}
                                description={it.description}
                                onCardClick={() => props.onWidgetSelect(it)}
                                onHelpClick={() => props.onShowHelp(it)}
                            />
                        ))}
                    </List>
                </Group>
            ))}
        </>
    );
};

export default AddWidget;
