import config from "../../includes/Config";
import Service from "./Service";
import bridge from "@vkontakte/vk-bridge";

import container from "../../container";
import LoggerInterface from "../Interfaces/LoggerInterface";

import {
    TOKEN_NOT_VALID_ERROR,
    USER_AUTH_ERROR,
    USER_DENIED_ERROR,
} from "../Constants";

import { UploadLandingImageParams } from "../Structures";

import TokensVKStorage from "./TokensVKStorage";

const METHOD_NOT_ALLOWED_ERROR = 15;

class FileService extends Service {
    launchParams: any;
    logger: LoggerInterface;

    constructor(launchParams, logger: LoggerInterface) {
        super();

        this.launchParams = launchParams;
        this.logger = logger;
    }

    async uploadImage(data: UploadLandingImageParams) {
        if (false === bridge.supports("VKWebAppAllowMessagesFromGroup")) {
            return {
                result: "error",
                message:
                    "Не поддерживается разрешение на отправку сообщений от сообщества. Выдайте разрешение на отправку сообщений от сообщества вручную в настройках сообщества.",
            };
        }

        try {
            const permission = await bridge.send(
                "VKWebAppAllowMessagesFromGroup",
                {
                    group_id: parseInt(this.launchParams.params.vk_group_id),
                }
            );

            if (!permission.result) {
                return {
                    result: "error",
                    message: "Разрешите отправку сообщений от сообщества",
                };
            }

            let token = await TokensVKStorage.getCommunityToken("docs");

            // Если токена нет в хранилище пользователя
            if (!token) {
                token = await this.getToken("docs");
            }

            // Если токен не получили ни из хранилища, ни на прямую из вк - какая то ошибка. Сообщим об этом пользователю
            if (!token) {
                return {
                    result: "error",
                    message: "Ошибка при получении токена сообщества",
                };
            }

            await TokensVKStorage.setCommunityToken("docs", token);

            const requestData = this._createRequestData({
                ...data,
                token: token,
            });

            const response = await fetch(config.filesUploadImageUrl, {
                method: "POST",
                body: requestData,
            });

            const result = await this.formattedImageResponse(response);

            if (result.error_code) {
                if (
                    parseInt(result.error_code, 10) === 15 &&
                    result.message ===
                        "15 Access denied: group messages are disabled"
                ) {
                    return {
                        result: "error",
                        message: "Включите сообщения в настройках сообщества",
                    };
                } else if (
                    [
                        USER_AUTH_ERROR,
                        TOKEN_NOT_VALID_ERROR,
                        METHOD_NOT_ALLOWED_ERROR,
                    ].indexOf(result.error_code) >= 0
                ) {
                    // Если какая то ошибка с токено - обнулим его
                    await TokensVKStorage.dropCommunityToken("docs");
                    return this.uploadImage(data);
                }
            }

            return result;
        } catch (e) {
            if (e.error_data && e.error_data.error_code === 4) {
                return {
                    result: "error",
                    message: "Разрешите отправку сообщений от сообщества",
                };
            }

            return {
                result: "error",
                message: e.message,
            };
        }
    }

    async getToken(scope) {
        if (false === bridge.supports("VKWebAppGetCommunityToken")) {
            throw new Error("Не поддерживается получение токена сообщества");
        }

        try {
            const token = await bridge.send(
                "VKWebAppGetCommunityToken",
                {
                    app_id: this.launchParams.params.vk_app_id,
                    group_id: this.launchParams.params.vk_group_id,
                    scope: scope,
                }
            );
            return token.access_token;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async requestToken() {
        const token = await this.getToken("docs");
        if (token) {
            await TokensVKStorage.setCommunityToken("docs", token);
            return {
                success: true,
                message:
                    "Токен для управления документами сообщества получен успешно",
            };
        } else {
            return {
                success: false,
                message:
                    "Токен для упраление документами сообщества не получен",
            };
        }
    }

    async formattedImageResponse(response) {
        const result = await response.json();

        if (result.result === "error" && result.errors) {
            return {
                result: "error",
                message: result.errors.image
                    ? result.errors.image[0]
                    : result.message,
            };
        }

        if (response.status === 200) {
            return result;
        } else {
            return {
                result: "error",
                message: result.errors ? result.errors[0] : result.message,
            };
        }
    }

    logError(e, code = "") {
        try {
            let message =
                e.error_data && e.error_data.error_reason
                    ? e.error_data.error_reason
                    : JSON.stringify(e);

            if (code) {
                message = `${message} ${code}`;
            }

            let error_code;

            if (
                e.error_data &&
                e.error_data.error_code &&
                e.error_data.error_code
            ) {
                error_code = e.error_data.error_code;
            }

            // Не логируем ошибку, если пользователь просто отменил публикацию
            if (error_code !== USER_DENIED_ERROR) {
                this.logger.error(
                    {
                        message: message,
                        code:
                            e.error_data && e.error_data.error_code
                                ? e.error_data.error_code
                                : JSON.stringify(e),
                    },
                    "widget_publish_error",
                    "WidgetService.ts"
                );
            }
        } catch (e) {
            console.log(e);
        }
    }
}

const instance = new FileService(
    container.get("LaunchParams"),
    container.get("logger")
);

export default instance;
